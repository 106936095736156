.bathrooms-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem;
  }


/* Media Query */
@media (max-width: 1380px) {

}
@media (max-width: 768px) {
  .bathrooms-container {
    padding: 2rem;
}
}

@media (max-width: 600px) {

}
