.private-sewage-system-main-div {
  display: flex;
  overflow: hidden;
  margin-bottom: 8rem;
  padding: 5rem;
}

.private-sewage-system-heading-left-mobile {
  display: none;
}

.private-sewage-system-heading-right-mobile {
  display: none;
}

.private-sewage-system-heading-desktop {
  font-size: 3rem;
  line-height: 1;
  font-weight: 400;
}

.private-sewage-system-header {
  font-size: 60px;
  line-height: normal;
  text-align: center;
}

.private-sewage-system-text-div-left {
  margin-left: 2rem;
}

.private-sewage-system-text-div-right {
  margin-right: 2rem;
}

.private-sewage-system-image-div {
  min-width: 50%;
}

.private-sewage-system-image-div-right-mobile {
  min-width: 50%;
}

.private-sewage-system-heading {
  font-size: 56px;
  font-weight: 400;
  line-height: normal;
}

.private-sewage-system-description-container {
  flex: 1;
}

.private-sewage-system-description-desktop {
  line-height: 2rem;
  font-size: 1.3rem;
}

.private-sewage-system-description-mobile {
  display: none;
}
.private-sewage-system-item-contact-us-desktop-button-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

/* Media Query */
@media (max-width: 1380px) {
  .private-sewage-system-title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .private-sewage-system-item-contact-us-desktop-button-container {
    display: none;
  }

  .private-sewage-system-main-div {
    flex-direction: column;
  }

  .private-sewage-system-heading-desktop {
    display: none;
  }

  .private-sewage-system-heading-right-mobile {
    display: none;
  }
  

  .private-sewage-system-heading-left-mobile {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: 400;
  }

  .private-sewage-system-heading-right-mobile {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 3rem;
    font-weight: 400;
  }
  .private-sewage-system-description-desktop {
    display: none;
  }

  .private-sewage-system-description-mobile {
    display: inherit;
    font-size: 1.5rem;
  }

  .private-sewage-system-text-div-left {
    margin-left: 0rem;
  }
  
  .private-sewage-system-text-div-right {
    margin-left: 0rem;
  }
  

  .private-sewage-system-title {
    font-size: 30px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .private-sewage-system-main-div {
    margin: 0rem 0rem 1rem;
  }
}
