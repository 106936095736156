@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"),
    url("./assets/fonts/Agustina.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Bold";
  src: local("Google Sans Bold"),
    url("./assets/fonts/GoogleSans-Bold.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Bold Italic";
  src: local("Google Sans Bold Italic"),
    url("./assets/fonts/GoogleSans-BoldItalic.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Italic";
  src: local("Google Sans Italic"),
    url("./assets/fonts/GoogleSans-Italic.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Medium";
  src: local("Google Sans Medium"),
    url("./assets/fonts/GoogleSans-Medium.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Medium Italic";
  src: local("Google Sans Medium Italic"),
    url("./assets/fonts/GoogleSans-MediumItalic.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Regular";
  src: local("Google Sans Regular"),
    url("./assets/fonts/GoogleSans-Regular.ttf") format("woff");
}
@font-face {
  font-family: "Raleway Black";
  src: local("Raleway Black"),
    url("./assets/fonts/Raleway-Black.ttf") format("woff");
}
@font-face {
  font-family: "Raleway Light";
  src: local("Raleway Light"),
    url("./assets/fonts/Raleway-Light.ttf") format("woff");
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  font: 19px / 23px Montserrat, "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: 16px;
    line-height: normal;
  }
}
