.heatpump-main-div {
  display: flex;
  overflow: hidden;
  margin-bottom: 6rem;
}

.heatpump-heading-left-mobile {
  display: none;
}

.heatpump-heading-right-mobile {
  display: none;
}

.heatpump-heading-desktop {
  font-size: 3rem;
  line-height: 1;
  font-weight: 400;
}

.heatpump-header {
  font-size: 60px;
  line-height: normal;
  text-align: center;
}

.heatpump-text-div-left {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.heatpump-text-div-right {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}

.heatpump-image-div {
  min-width: 50%;
}

.heatpump-image-div-right-mobile {
  min-width: 50%;
}

.heatpump-heading {
  font-size: 56px;
  font-weight: 400;
  line-height: normal;
}

.heatpump-description-desktop {
  flex: 1;
  line-height: 2rem;
  font-size: 1.3rem;
}

.heatpump-contact-us-button-container {
  display: flex;
  justify-content: center;
}

.heatpump-description-mobile {
  display: none;
}

.heatpump-contact-us-button-mobile {
  display: none;
}

.heatpump-contact-us-button-desktop {
  text-decoration: none;
  padding: 1.5rem 3rem 1.5rem;
  font-size: 1.5rem;
}

/* Media Query */
@media (max-width: 1380px) {

}
@media (max-width: 768px) {
  .heatpump-main-div {
    flex-direction: column;
  }

  .heatpump-heading-desktop {
    display: none;
  }

  .heatpump-heading-right-mobile {
    display: none;
  }

  .heatpump-heading-left-mobile {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: 400;
    text-align: center;
  }

  .heatpump-heading-right-mobile {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 3rem;
    font-weight: 400;
    text-align: center;
  }
  .heatpump-description-desktop {
    display: none;
  }

  .heatpump-contact-us-button-desktop {
    display: none;
  }  

  .heatpump-description-mobile {
    display: inherit;
    font-size: 1.2rem;
  }

  .heatpump-contact-us-button-mobile {
    display: inherit;
    text-decoration: none;
    padding: 1.5rem 3rem 1.5rem;
    font-size: 1.5rem;
    text-align: center;
  }

  .heatpump-text-div-left {
    margin-left: 0rem;
    margin-bottom: 5rem;
  }
  
  .heatpump-text-div-right {
    display: flex;
    margin-left: 0rem;
  }
  

  .heatpump-title {
    font-size: 30px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .heatpump-main-div {
    margin: 0rem 0rem 1rem;
  }

  .heatpump-description-mobile {
    display: inherit;
    font-size: 1rem;
  }
}
