.contact-us-button-desktop {
    padding: 1rem 4rem 1rem;
    font-size: 1.6rem;
    border-radius: 0.2rem;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0.1rem 0.1rem 0.2rem #000;
}

@media (max-width: 1380px) {
    .contact-us-button-desktop {
        margin-top: 1rem;
    }  
}

@media (max-width: 768px) {

    .contact-us-button-desktop {
        display: none;
    }
}
  

@media (max-width: 600px) {
    
    .contact-us-button {
        margin-right: 0rem;
    }
}
  
  