.private-sewage-system-bg-image {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
    url(../../assets/images/private_sewage_system_background.jpg);
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
}

.private-sewage-system-bg-image-text-container {
  display: flex;
  flex-direction: column;
  color: white;
  line-height: 1;
}

.private-sewage-system-bg-image-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  padding-top: 2rem;
  padding-left: 10rem;
  padding-bottom: 20rem
}

.private-sewage-system-bg-image-contact-us-container-desktop {
  display: flex;
  margin: 2rem 0rem 0rem;
}

.private-sewage-system-bg-image-text-title {
  text-align: center;
  font-size: 6rem;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.private-sewage-system-bg-image-text-subtitle-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.private-sewage-system-bg-image-text-subtitle {
  font-size: 2rem;
}

@media (max-width: 1380px) {

  .private-sewage-system-bg-image-text-subtitle-container {
    margin-bottom: 0rem;
  }

  .private-sewage-system-bg-image-text-title {
    font-size: 4rem;
  }
}

@media (max-width: 768px) {
  .private-sewage-system-bg-image-form-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .private-sewage-system-bg-image-text-container {
    display: flex;
  }

  .private-sewage-system-bg-image-text-title {
    font-size: 3rem;
  }

  .private-sewage-system-bg-image-text-subtitle   {
    font-size: 1.8rem;
  }

}

@media (max-width: 600px) { 
  .bg-image-text-container {
  }
}
