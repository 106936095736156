.offerings {
  display: flex;
  flex-direction: row;
  max-width: 90%;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.offerings-title-container {
  display: flex;
  justify-content: center;
  font-size: 2rem;
}

.offerings-container {
  display: flex;
  justify-content: center;
}

/* Media Query */
@media (max-width: 1380px) {
  .offerings {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .offerings {
    margin: 0rem 0rem 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

