.bathroom-main-div {
  display: flex;
  overflow: hidden;
  margin-bottom: 8rem;
}

.bathroom-heading-left-mobile {
  display: none;
}

.bathroom-heading-right-mobile {
  display: none;
}

.bathroom-heading-desktop {
  font-size: 3rem;
  line-height: 1;
  font-weight: 400;
}

.bathroom-text-div-left {
  margin-left: 2rem;
}

.bathroom-text-div-right {
  margin-right: 2rem;
}

.bathroom-image-div {
  min-width: 50%;
}

.bathroom-image-div-right-mobile {
  min-width: 50%;
}

.bathroom-description-container {
  flex: 1;
}

.bathroom-description-desktop {
  line-height: 2rem;
  font-size: 1.3rem;
}

.bathroom-description-mobile {
  display: none;
}

.bathroom-item-contact-us-desktop-button-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

/* Media Query */
@media (max-width: 1380px) {
  .bathroom-title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .bathroom-item-contact-us-desktop-button-container {
    display: none;
  }

  .bathroom-main-div {
    flex-direction: column;
  }

  .bathroom-heading-desktop {
    display: none;
  }

  .bathroom-heading-right-mobile {
    display: none;
  }
  

  .bathroom-heading-left-mobile {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: 400;
  }

  .bathroom-heading-right-mobile {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 3rem;
    font-weight: 400;
  }
  .bathroom-description-desktop {
    display: none;
  }

  .bathroom-description-mobile {
    display: inherit;
    font-size: 1.5rem;
  }

  .bathroom-text-div-left {
    margin-left: 0rem;
  }
  
  .bathroom-text-div-right {
    margin-left: 0rem;
  }
  

  .bathroom-title {
    font-size: 30px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .bathroom-main-div {
    margin: 0rem 0rem 1rem;
  }
}
