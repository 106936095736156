.footer-container {
  display: flex;
  flex-direction: column;
}

.footer-top-container {
  display: flex;
  flex-direction: row;
}

.footer-bottom-container {
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  margin: 2rem;
  text-align: center;
}

.footer-bottom-info {
  margin: 0.5rem;
}

.footer-links-container {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  line-height: 1.2;
  max-width: 80%;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.footer-links-about-container {
  margin: 1rem;
  text-align: center;
}

.footer-links-name-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}


.footer-links-header {
  font-size: 1.5rem;
}

.footer-links-text {
  font-size: 1.2rem;
}

.footer-section {
  flex: 1;
  height: 20rem;
}


/* Media Query */
@media (max-width: 1380px) {
  .footer-links-header {
    font-size: 1.3rem;
  }

  .footer-links-text {
    font-size: 1rem;
  }


.footer-bottom-container {
  font-size: 1rem;
}
  
  
}

@media (max-width: 768px) {
  .footer-links-header {
    font-size: 1.2rem;
  }
}