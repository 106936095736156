.contact-us-container-mobile {
    display: flex;
    justify-content: center;
}

.contact-us-button-mobile {
    display: none;
}

@media (max-width: 1380px) {
    .contact-us-button-mobile {
        margin-top: 1rem;
        margin-bottom: 1rem
    }  
}

@media (max-width: 768px) {
    .contact-us-container-mobile {
        max-width: 90%;
        margin: 5rem auto 5rem;
    }
    
    .contact-us-button-mobile {
        display: inherit;
        padding: 1rem 3rem 1rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: normal;
        justify-content: center;
        border-radius: 0.2rem;
        cursor: pointer;
        text-decoration: none;
        box-shadow: 0.1rem 0.1rem 0.2rem #000
    }
}
  

@media (max-width: 600px) {

}
  
  