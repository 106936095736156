.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40%;
  max-width: 90%;
  margin-top: 3rem;
  margin-bottom: 10rem;
}

.contact-form-heading {
  width: 100%;
  text-align: center;
  font-size: 5rem;
  line-height: normal;
}

.contact-form-name-input {
  width: 100%;
  height: 3rem;
  font-size: 1.3rem;
  padding-left: 0.6rem;
  border: none;
  border-bottom: 1px solid #000;
  background-color: #f0f0f0;
}

.contact-form-name-input::placeholder {
  color: gray;
}

.contact-form-email-input {
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  font-size: 1.3rem;
  padding-left: 0.6rem;
  border: none;
  border-bottom: 1px solid #000;
  background-color: #f0f0f0;
}

.contact-form-email-input::placeholder {
  color: gray;
}


.contact-form-phone-number-input {
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  font-size: 1.3rem;
  padding-left: 0.6rem;
  border: none;
  border-bottom: 1px solid #000;
  background-color: #f0f0f0;
}

.contact-form-phone-number-input::placeholder {
  color: gray;
}

.contact-form-address-input {
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  font-size: 1.3rem;
  padding-left: 0.6rem;
  border: none;
  border-bottom: 1px solid #000;
  background-color: #f0f0f0;
}

.contact-form-address-input::placeholder {
  color: gray;
}


.contact-form-message-input {
  width: 100%;
  height: 10rem;
  margin-top: 1rem;
  font-size: 1.3rem;
  padding-left: 0.6rem;
  padding-top: 0.6rem;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  border-bottom: 1px solid #000;
  background-color: #f0f0f0;
}

.contact-form-message-input::placeholder {
  color: gray;
}

.contact-form-title {
  font-size: 4rem;
  line-height: normal;
}

.contact-form-subtitle {
  font-size: 1.5rem;
  line-height: normal;
}

.contact-form-submit {
  padding: 1rem 4rem 1rem;
  margin-top: 2rem;
  font-size: 1.4rem;
  font-weight: normal;
  border-radius: 0.2rem;
  cursor: pointer;
  text-decoration: none;
}

.contact-form-logo-container {
  margin-bottom: 5rem;
}

/* Media Query */
@media (max-width: 1380px) {
  .contact-form {
    flex-direction: column;
    align-items: center;
    min-width: 70%;
  }

  .contact-form-submit {
    font-size: 1.4rem;
  }
}

@media (max-width: 768px) {
  .contact-form-heading {
    font-size: 3rem;
  }

  .contact-form {
    min-width: 80%;
  }

  .contact-form-submit {
    font-size: 1.2rem;
  }
}
