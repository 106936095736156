.offering-card-container {
  display: flex;
  justify-content: center;
  flex: 1;
  text-align: center;
  flex-direction: column;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
}

.offering-card-container:hover {
  box-shadow: 0.5rem 0.5rem 0.5rem #000;
}

.offering-card-container:first-child {
  margin-right: 5rem;
}

.offering-card-container:last-child {
  margin-left: 5rem;
}


/* .offering-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem;
  padding: 0rem 0rem 2rem; /* top right-left bottom

} */

.offering-icon {
  flex: 10;
  text-align: center;
}

/* Card  Styling */
.offering-card-title {
  margin-top: 1rem;
  font-size: 2rem;
  line-height: normal;
}

.offering-card-description {
  text-align: center;
  justify-content: center;
  max-width: 90%;
  flex: 1;
  font-size: 1.3rem;
  margin: 0 auto;
}

.offering-card-button {
  padding: 1rem 1rem 1rem;
  margin: 2rem auto 1rem;
  min-width: 70%;
  font-size: 1.3rem;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 1px 2px 0px #000;
}


/* Media Query */
@media (max-width: 1380px) {
  .offering-card-container {
    margin-top: 5rem;
  }
  

  .offering-card-container:first-child {
    margin-right: 0rem;
  }
  
  .offering-card-container:last-child {
    margin-left: 0rem;
  }

  .card-title {
    font-size: 25px;
  }
}
@media (max-width: 768px) {

  .offering-card-button {
    font-size: 1rem;
  }
  

}

@media (max-width: 600px) {
  .offering-container {
    max-width: 90%;
  }

  .offering-card-description {
    font-size: 1rem;
  }
}
