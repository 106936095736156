.hello-section-container {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-top: 4rem;
}



@media (max-width: 768px) {
  .hello-section-container {
    margin-top: 1rem;
  }
}